import { toast } from 'react-toastify'
import URL from '../../utils/URL'

// Action Types
const GET_PRIVACY_POLICY_REQUEST = 'GET_PRIVACY_POLICY_REQUEST'
const GET_PRIVACY_POLICY_SUCCESS = 'GET_PRIVACY_POLICY_SUCCESS'
const GET_PRIVACY_POLICY_FAILURE = 'GET_PRIVACY_POLICY_FAILURE'

// Action Creators
export const getPrivacyPolicyRequest = () => ({
  type: GET_PRIVACY_POLICY_REQUEST,
})

export const getPrivacyPolicySuccess = (data) => ({
  type: GET_PRIVACY_POLICY_SUCCESS,
  payload: data,
})

export const getPrivacyPolicyFailure = (error) => ({
  type: GET_PRIVACY_POLICY_FAILURE,
  error,
})

// Thunk Action for Fetching Privacy Policy
export const fetchPrivacyPolicy = () => {
  return async (dispatch) => {
    dispatch(getPrivacyPolicyRequest())

    try {
      const response = await fetch(URL.FIREBASE + URL.END_POINT.privacy_policy, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok) {
        toast.error(data.message)
        throw new Error(data.message)
      }

      dispatch(getPrivacyPolicySuccess(data))
      // Optional success toast message:
      // toast.success('Privacy policy fetched successfully');
    } catch (error) {
      dispatch(getPrivacyPolicyFailure(error.message))
      toast.error(error.message)
    }
  }
}

// Initial State
const initialPrivacyPolicyState = {
  privacyPolicy: null,
  loading: false,
  error: null,
}

// Reducer
const privacyPolicyReducer = (state = initialPrivacyPolicyState, action) => {
  switch (action.type) {
    case GET_PRIVACY_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        privacyPolicy: action.payload,
        loading: false,
        error: null,
      }
    case GET_PRIVACY_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default privacyPolicyReducer
