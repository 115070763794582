import { toast } from 'react-toastify'
import URL from 'src/utils/URL'

// Action Types
const GET_MENUS_REQUEST = 'GET_MENUS_REQUEST'
const GET_MENUS_SUCCESS = 'GET_MENUS_SUCCESS'
const GET_MENUS_FAILURE = 'GET_MENUS_FAILURE'

// Action Creators
export const getMenusRequest = () => ({
  type: GET_MENUS_REQUEST,
})

export const getMenusSuccess = (menus) => ({
  type: GET_MENUS_SUCCESS,
  menus,
})

export const getMenusFailure = (error) => ({
  type: GET_MENUS_FAILURE,
  error,
})

// Thunk Action for Fetching Menus
export const fetchMenus = () => {
  return (dispatch) => {
    dispatch(getMenusRequest())

    fetch(URL.BASE_URL + URL.END_POINT.menus, {
      method: 'GET',
      // mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then(async (menus) => {
        await dispatch(getMenusSuccess(menus))
        // await toast('Menus fetched successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(getMenusFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

// Initial State
const initialMenusState = {
  menus: null,
  loading: false,
  error: null,
}

// Reducer
const menusReducer = (state = initialMenusState, action) => {
  switch (action.type) {
    case GET_MENUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_MENUS_SUCCESS:
      return {
        ...state,
        menus: action.menus.menus,
        loading: false,
        error: null,
      }
    case GET_MENUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default menusReducer
