import { toast } from 'react-toastify'
import URL from '../../utils/URL'

const FETCH_MASTERDATA_REQUEST = 'FETCH_MASTERDATA_REQUEST'
const FETCH_MASTERDATA_SUCCESS = 'FETCH_MASTERDATA_SUCCESS'
const FETCH_MASTERDATA_FAILURE = 'FETCH_MASTERDATA_FAILURE'

const fetchMasterDataRequest = () => ({ type: FETCH_MASTERDATA_REQUEST })
const fetchMasterDataSuccess = (data) => ({ type: FETCH_MASTERDATA_SUCCESS, payload: data })
const fetchMasterDataFailure = (error) => ({ type: FETCH_MASTERDATA_FAILURE, error })

export const fetchMasterData = (body) => async (dispatch) => {
  dispatch(fetchMasterDataRequest())
  try {
    const getToken = localStorage.getItem('token')
    const USERID = localStorage.getItem('USERID')
    const response = await fetch(URL.BASE_URL + URL.END_POINT.masterdata + body, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer token=${getToken}`,
        USERID: USERID,
      },
    })

    const data = await response.json()

    if (!response.ok) {
      toast.error(data.message)
      dispatch(fetchMasterDataFailure(data.message))
    } else {
      // console.log('Master data fetched successfully')
      dispatch(fetchMasterDataSuccess(data))
    }
  } catch (error) {
    toast.error(error.message)
    dispatch(fetchMasterDataFailure(error.message))
  }
}

const initialState = {
  loading: false,
  masterData: null,
  error: null,
}

const masterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MASTERDATA_REQUEST:
      return { ...state, loading: true, error: null }

    case FETCH_MASTERDATA_SUCCESS:
      return { ...state, loading: false, masterData: action.payload }

    case FETCH_MASTERDATA_FAILURE:
      return { ...state, loading: false, error: action.error }

    default:
      return state
  }
}

export default masterDataReducer
