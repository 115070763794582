import { toast } from 'react-toastify'
import URL from '../../utils/URL'

const GET_CART_REQUEST = 'GET_CART_REQUEST'
const GET_CART_SUCCESS = 'GET_CART_SUCCESS'
const GET_CART_FAILURE = 'GET_CART_FAILURE'

const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST'
const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS'
const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE'

const UPDATE_CART_REQUEST = 'UPDATE_CART_REQUEST'
const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS'
const UPDATE_CART_FAILURE = 'UPDATE_CART_FAILURE'

const DELETE_CART_REQUEST = 'DELETE_CART_REQUEST'
const DELETE_CART_SUCCESS = 'DELETE_CART_SUCCESS'
const DELETE_CART_FAILURE = 'DELETE_CART_FAILURE'

const getCartRequest = () => ({ type: GET_CART_REQUEST })
const getCartSuccess = (data) => ({ type: GET_CART_SUCCESS, payload: data })
const getCartFailure = (error) => ({ type: GET_CART_FAILURE, error })

const addToCartRequest = () => ({ type: ADD_TO_CART_REQUEST })
const addToCartSuccess = (data) => ({ type: ADD_TO_CART_SUCCESS, payload: data })
const addToCartFailure = (error) => ({ type: ADD_TO_CART_FAILURE, error })

const updateCartRequest = () => ({ type: UPDATE_CART_REQUEST })
const updateCartSuccess = (data) => ({ type: UPDATE_CART_SUCCESS, payload: data })
const updateCartFailure = (error) => ({ type: UPDATE_CART_FAILURE, error })

const deleteCartRequest = () => ({ type: DELETE_CART_REQUEST })
const deleteCartSuccess = (data) => ({ type: DELETE_CART_SUCCESS, payload: data })
const deleteCartFailure = (error) => ({ type: DELETE_CART_FAILURE, error })

export const getToCartFunction = () => {
  return async (dispatch) => {
    dispatch(getCartRequest())

    try {
      const jsonValue = await window.localStorage.getItem('USERID')
      const response = await fetch(URL.BASE_URL + URL.END_POINT.carts + jsonValue?.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok) {
        toast.error(data.message)
        dispatch(getCartFailure(data.message))
      } else {
        dispatch(getCartSuccess(data))
      }
    } catch (error) {
      dispatch(getCartFailure(error.message))
      toast.error(error.message)
    }
  }
}

export const addToCartFunction = (body) => {
  return async (dispatch) => {
    dispatch(addToCartRequest())

    try {
      const response = await fetch(URL.BASE_URL + URL.END_POINT.cart, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(body),
      })

      const data = await response.json()

      if (!response.ok) {
        toast.error(data.message)
        dispatch(addToCartFailure(data.message))
      } else {
        dispatch(addToCartSuccess(data))
        dispatch(getToCartFunction())
      }
    } catch (error) {
      dispatch(addToCartFailure(error.message))
      toast.error(error.message)
    }
  }
}

export const updateCartFunction = (body) => {
  return async (dispatch) => {
    dispatch(updateCartRequest())

    try {
      const response = await fetch(URL.BASE_URL + URL.END_POINT.cart, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(body),
      })

      const data = await response.json()

      if (!response.ok) {
        toast.error(data.message)
        dispatch(updateCartFailure(data.message))
      } else {
        dispatch(updateCartSuccess(data))
        dispatch(getToCartFunction())
      }
    } catch (error) {
      dispatch(updateCartFailure(error.message))
      toast.error(error.message)
    }
  }
}

export const deleteCartFunction = (body) => {
  return async (dispatch) => {
    dispatch(deleteCartRequest())

    try {
      const response = await fetch(URL.BASE_URL + URL.END_POINT.cart, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(body),
      })

      const data = await response.json()

      if (!response.ok) {
        toast.error(data.message)
        dispatch(deleteCartFailure(data.message))
      } else {
        dispatch(deleteCartSuccess(data))
        dispatch(getToCartFunction())
      }
    } catch (error) {
      dispatch(deleteCartFailure(error.message))
      toast.error(error.message)
    }
  }
}

const initialCartState = {
  cartState: null,
  loading: false,
  error: null,
}

const cartReducer = (state = initialCartState, action) => {
  switch (action.type) {
    case GET_CART_REQUEST:
    case ADD_TO_CART_REQUEST:
    case UPDATE_CART_REQUEST:
    case DELETE_CART_REQUEST:
      return { ...state, loading: true, error: null }

    case GET_CART_SUCCESS:
      return { ...state, cartState: action.payload.data, loading: false, error: null }

    case ADD_TO_CART_SUCCESS:
    case UPDATE_CART_SUCCESS:
    case DELETE_CART_SUCCESS:
      return { ...state, loading: false, error: null }

    case GET_CART_FAILURE:
    case ADD_TO_CART_FAILURE:
    case UPDATE_CART_FAILURE:
    case DELETE_CART_FAILURE:
      return { ...state, loading: false, error: action.error }

    default:
      return state
  }
}

export default cartReducer
