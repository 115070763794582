const GoogleMaps = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
const MapApiKey = 'AIzaSyBIIO8kxW-ZhiqhNcmD2Qkfjbc5y2LVsqM';

const headers = {
  Accept: 'application/json',
};

export const GoogleApi = {
  GetLocation: async (myLat, myLon) => {
    return await fetch(GoogleMaps + myLat + ',' + myLon + '&key=' + MapApiKey, {
      method: 'GET',
      headers: headers,
    }).then(res => res.json());
  },
};
