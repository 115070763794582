const ADD_TO_CART = 'ADD_TO_CART'
const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
const CLEAR_CART = 'CLEAR_CART'
const SET_LOADING_STATE = 'SET_LOADING_STATE'

export const addToCart = (item) => ({
  type: ADD_TO_CART,
  payload: item,
})

export const removeFromCart = (item) => ({
  type: REMOVE_FROM_CART,
  payload: item,
})

export const clearCart = () => ({
  type: CLEAR_CART,
})

export const setLoadingState = (isLoading) => ({
  type: SET_LOADING_STATE,
  payload: isLoading,
})

const initialState = {
  items: [],
  isLoading: false,
}

const addtocartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      const { id } = action.payload
      const existingItem = state.items.find((item) => item.id === id)

      if (existingItem) {
        return {
          ...state,
          items: state.items.map((item) =>
            item.id === id ? { ...item, quantity: Math.min(item.quantity + 1, 10) } : item,
          ),
        }
      } else {
        return {
          ...state,
          items: [...state.items, { ...action.payload, quantity: 1 }],
        }
      }
    }

    case REMOVE_FROM_CART: {
      const { id } = action.payload
      const existingItem = state.items.find((item) => item.id === id)

      if (existingItem && existingItem.quantity > 1) {
        return {
          ...state,
          items: state.items.map((item) =>
            item.id === id ? { ...item, quantity: Math.min(item.quantity - 1, 10) } : item,
          ),
        }
      } else {
        return {
          ...state,
          items: state.items.filter((item) => item.id !== id),
        }
      }
    }

    case CLEAR_CART:
      return {
        ...state,
        items: [],
      }

    case SET_LOADING_STATE:
      return {
        ...state,
        isLoading: action.payload,
      }

    default:
      return state
  }
}

export default addtocartReducer
