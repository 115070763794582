import React, { Suspense, useEffect } from 'react'
import Loading from './common/Loading'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchBanner } from './redux/reducer/getBanner'
import { fetchMenus } from './redux/reducer/getMenus'
import { getToCartFunction } from './redux/reducer/cart'
import { getFCMToken } from './utils/getFCMToken'
import { onMessage } from 'firebase/messaging'
import { messaging } from './utils/firebase'
import { fetchLocation } from './redux/reducer/getLocation'
import { authProtectedRoutes, publicRoutes } from './routes/routes'

// Lazy loading components
const NonAuthLayout = React.lazy(() => import('./routes/NonAuthLayout'))
const Authmiddleware = React.lazy(() => import('./routes/Authmiddleware'))

const App = () => {
  const dispatch = useDispatch()

  // Fetch geolocation from the browser and dispatch location data
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords
          try {
            await dispatch(fetchLocation(latitude, longitude))
          } catch (error) {
            console.error('Error dispatching location:', error)
          }
        },
        (error) => console.error('Error getting location:', error),
        { enableHighAccuracy: true },
      )
    } else {
      console.warn('Geolocation is not supported by this browser.')
    }
  }, [dispatch])

  // Fetch banner and menu data, and get cart data if the user is logged in
  useEffect(() => {
    dispatch(fetchBanner())
    dispatch(fetchMenus())

    if (localStorage.getItem('token') && localStorage.getItem('USERID')) {
      dispatch(getToCartFunction())
    }
  }, [dispatch])

  // Fetch Firebase Cloud Messaging (FCM) token
  useEffect(() => {
    const fetchFCMToken = async () => {
      try {
        await getFCMToken() // Assuming token is handled in `getFCMToken` utility
      } catch (error) {
        console.error('Error fetching FCM token:', error)
      }
    }

    fetchFCMToken()
  }, [])

  // Listen for incoming Firebase messages
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Message received:', payload)
      // Add logic to handle messages, e.g., notifications
    })

    return () => {
      unsubscribe() // Cleanup listener on component unmount
    }
  }, [])

  return (
    <BrowserRouter>
      {/* Fallback spinner while components and routes are being lazily loaded */}
      <Suspense fallback={<Loading isLoading={true} />}>
        <Routes>
          {/* Public Routes */}
          {publicRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <Suspense fallback={<Loading isLoading={true} />}>
                  <NonAuthLayout>{route.component}</NonAuthLayout>
                </Suspense>
              }
            />
          ))}

          {/* Authenticated Routes */}
          {authProtectedRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <Suspense fallback={<Loading isLoading={true} />}>
                  <Authmiddleware>{route.component}</Authmiddleware>
                </Suspense>
              }
            />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
