import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging } from 'firebase/messaging'
import { getRemoteConfig } from 'firebase/remote-config'

const firebaseConfig = {
  apiKey: 'AIzaSyCrYUiQ9OlmI1YR3NMjWPxOXx9ermQHsrA',
  authDomain: 'freshymeat-app.firebaseapp.com',
  databaseURL: 'https://freshymeat-app-default-rtdb.firebaseio.com',
  projectId: 'freshymeat-app',
  storageBucket: 'freshymeat-app.appspot.com',
  messagingSenderId: '196894240013',
  appId: '1:196894240013:web:8751467230dc57e69a1444',
  measurementId: 'G-CZLV8L84YR',
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)
const analytics = getAnalytics(app)
const remoteConfig = getRemoteConfig(app)

export { messaging, analytics, remoteConfig }
