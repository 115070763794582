import { toast } from 'react-toastify'
import URL from '../../utils/URL'

const GET_ORDER_HISTORY_REQUEST = 'GET_ORDER_HISTORY_REQUEST'
const GET_ORDER_HISTORY_SUCCESS = 'GET_ORDER_HISTORY_SUCCESS'
const GET_ORDER_HISTORY_FAILURE = 'GET_ORDER_HISTORY_FAILURE'

export const getOrderHistoryRequest = () => ({
  type: GET_ORDER_HISTORY_REQUEST,
})

export const getOrderHistorySuccess = (orderHistory) => ({
  type: GET_ORDER_HISTORY_SUCCESS,
  orderHistory,
})

export const getOrderHistoryFailure = (error) => ({
  type: GET_ORDER_HISTORY_FAILURE,
  error,
})

export const fetchOrderHistory = () => {
  return async (dispatch) => {
    dispatch(getOrderHistoryRequest())

    try {
      const userId = await window.localStorage.getItem('USERID')

      const response = await fetch(
        `${URL.BASE_URL}${URL.END_POINT.orderhistory}${userId?.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      )

      if (!response.ok) {
        const { message } = await response.json()
        throw new Error(message)
      }

      const data = await response.json()
      dispatch(getOrderHistorySuccess(data))
    } catch (error) {
      dispatch(getOrderHistoryFailure(error.message))
      toast(error.message, { type: 'error' })
    }
  }
}

const initialOrderHistoryState = {
  orderHistory: [],
  loading: false,
  error: null,
}

const orderHistoryReducer = (state = initialOrderHistoryState, action) => {
  switch (action.type) {
    case GET_ORDER_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        orderHistory: action.orderHistory.data,
        loading: false,
        error: null,
      }
    case GET_ORDER_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default orderHistoryReducer
