import { getToken, deleteToken } from 'firebase/messaging'
import { messaging } from './firebase'

export const getFCMToken = async () => {
  if (!('Notification' in window)) {
    console.log('This browser does not support notifications.')
    return
  }
  return Notification.requestPermission()
    .then((permission) => {
      if (permission === 'granted') {
        return getToken(messaging, { vapidKey: process.env.PUBLIC_VAPID_KEY })
      } else {
        throw new Error('Notification not granted')
      }
    })
    .catch((err) => {
      console.log('Error getting FCM token: ', err)
      throw err
    })
}

export const removeFCMToken = async () => {
  try {
    const isTokenDeleted = await deleteToken(messaging)
    if (isTokenDeleted) {
      console.log('FCM token deleted successfully')
    } else {
      console.log('FCM token could not be deleted')
    }
  } catch (err) {
    console.error('Error deleting FCM token:', err)
  }
}
