import { toast } from 'react-toastify'
import URL from '../../utils/URL'

// Action Types
const GET_TERMS_AND_CONDITIONS_REQUEST = 'GET_TERMS_AND_CONDITIONS_REQUEST'
const GET_TERMS_AND_CONDITIONS_SUCCESS = 'GET_TERMS_AND_CONDITIONS_SUCCESS'
const GET_TERMS_AND_CONDITIONS_FAILURE = 'GET_TERMS_AND_CONDITIONS_FAILURE'

// Action Creators
export const getTermsAndConditionsRequest = () => ({
  type: GET_TERMS_AND_CONDITIONS_REQUEST,
})

export const getTermsAndConditionsSuccess = (data) => ({
  type: GET_TERMS_AND_CONDITIONS_SUCCESS,
  payload: data,
})

export const getTermsAndConditionsFailure = (error) => ({
  type: GET_TERMS_AND_CONDITIONS_FAILURE,
  error,
})

// Thunk Action for Fetching Terms and Conditions
export const fetchTermsAndConditions = () => {
  return async (dispatch) => {
    dispatch(getTermsAndConditionsRequest())

    try {
      const response = await fetch(URL.FIREBASE + URL.END_POINT.terms_and_conditions, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok) {
        toast.error(data.message)
        throw new Error(data.message)
      }

      dispatch(getTermsAndConditionsSuccess(data))
      // Optional success toast message:
      // toast.success('Terms and Conditions fetched successfully');
    } catch (error) {
      dispatch(getTermsAndConditionsFailure(error.message))
      toast.error(error.message)
    }
  }
}

// Initial State
const initialTermsAndConditionsState = {
  termsAndConditions: null,
  loading: false,
  error: null,
}

// Reducer
const termsAndConditionsReducer = (state = initialTermsAndConditionsState, action) => {
  switch (action.type) {
    case GET_TERMS_AND_CONDITIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_TERMS_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        termsAndConditions: action.payload,
        loading: false,
        error: null,
      }
    case GET_TERMS_AND_CONDITIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default termsAndConditionsReducer
