import { toast } from 'react-toastify'
import URL from 'src/utils/URL'

const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE'

export const getProfileRequest = () => ({
  type: GET_PROFILE_REQUEST,
})

export const getProfileSuccess = (profile) => ({
  type: GET_PROFILE_SUCCESS,
  profile,
})

export const getProfileFailure = (error) => ({
  type: GET_PROFILE_FAILURE,
  error,
})

export const fetchProfile = () => {
  return (dispatch) => {
    const getToken = localStorage.getItem('token')
    const USERID = localStorage.getItem('USERID')
    dispatch(getProfileRequest())
    fetch(URL.BASE_URL + URL.END_POINT.myprofile, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer token=${getToken}`,
        USERID: USERID,
      }),
      credentials: 'include',
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then(async (profile) => {
        await dispatch(getProfileSuccess(profile))
      })
      .catch((error) => {
        dispatch(getProfileFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialProfileState = {
  profile: null,
  loading: false,
  error: null,
}

const profileReducer = (state = initialProfileState, action) => {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.profile.myprofile,
        loading: false,
        error: null,
      }
    case GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default profileReducer
