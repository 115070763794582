import { toast } from 'react-toastify'
import URL from 'src/utils/URL'

const GET_CHECKOUT_REQUEST = 'GET_CHECKOUT_REQUEST'
const GET_CHECKOUT_SUCCESS = 'GET_CHECKOUT_SUCCESS'
const GET_CHECKOUT_FAILURE = 'GET_CHECKOUT_FAILURE'

const ADD_CHECKOUT_REQUEST = 'ADD_CHECKOUT_REQUEST'
const ADD_CHECKOUT_SUCCESS = 'ADD_CHECKOUT_SUCCESS'
const ADD_CHECKOUT_FAILURE = 'ADD_CHECKOUT_FAILURE'

const DELETE_CHECKOUT_REQUEST = 'DELETE_CHECKOUT_REQUEST'
const DELETE_CHECKOUT_SUCCESS = 'DELETE_CHECKOUT_SUCCESS'
const DELETE_CHECKOUT_FAILURE = 'DELETE_CHECKOUT_FAILURE'

const getCheckoutRequest = () => ({ type: GET_CHECKOUT_REQUEST })
const getCheckoutSuccess = (data) => ({ type: GET_CHECKOUT_SUCCESS, payload: data })
const getCheckoutFailure = (error) => ({ type: GET_CHECKOUT_FAILURE, error })

const addCheckoutRequest = () => ({ type: ADD_CHECKOUT_REQUEST })
const addCheckoutSuccess = (data) => ({ type: ADD_CHECKOUT_SUCCESS, payload: data })
const addCheckoutFailure = (error) => ({ type: ADD_CHECKOUT_FAILURE, error })

const deleteCheckoutRequest = () => ({ type: DELETE_CHECKOUT_REQUEST })
const deleteCheckoutSuccess = (data) => ({ type: DELETE_CHECKOUT_SUCCESS, payload: data })
const deleteCheckoutFailure = (error) => ({ type: DELETE_CHECKOUT_FAILURE, error })

export const getCheckoutFunction = () => {
  return async (dispatch) => {
    dispatch(getCheckoutRequest())
    try {
      const jsonValue = await window.localStorage.getItem('USERID')
      const response = await fetch(
        URL.BASE_URL + URL.END_POINT.checkout + '?id=' + jsonValue?.toString(),
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      )

      const data = await response.json()

      if (!response.ok) {
        toast.error(data.message)
        dispatch(getCheckoutFailure(data.message))
      } else {
        dispatch(getCheckoutSuccess(data))
      }
    } catch (error) {
      dispatch(getCheckoutFailure(error.message))
      toast.error(error.message)
    }
  }
}

export const addCheckoutFunction = (body) => {
  return async (dispatch) => {
    dispatch(addCheckoutRequest())
    try {
      const response = await fetch(URL.BASE_URL + URL.END_POINT.checkout, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(body),
      })

      const data = await response.json()

      if (!response.ok) {
        toast.error(data.message)
        dispatch(addCheckoutFailure(data.message))
      } else {
        dispatch(addCheckoutSuccess(data))
        return data
      }
    } catch (error) {
      dispatch(addCheckoutFailure(error.message))
      toast.error(error.message)
    }
  }
}

export const deleteCheckoutFunction = (id) => {
  return async (dispatch) => {
    dispatch(deleteCheckoutRequest())
    try {
      const response = await fetch(URL.BASE_URL + URL.END_POINT.checkout + '?id=' + id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok) {
        toast.error(data.message)
        dispatch(deleteCheckoutFailure(data.message))
      } else {
        dispatch(deleteCheckoutSuccess(data))
      }
    } catch (error) {
      dispatch(deleteCheckoutFailure(error.message))
      toast.error(error.message)
    }
  }
}

const initialCheckoutState = {
  checkoutState: null,
  loading: false,
  error: null,
}

const checkoutReducer = (state = initialCheckoutState, action) => {
  switch (action.type) {
    case GET_CHECKOUT_REQUEST:
    case ADD_CHECKOUT_REQUEST:
    case DELETE_CHECKOUT_REQUEST:
      return { ...state, loading: true, error: null }

    case GET_CHECKOUT_SUCCESS:
      return { ...state, checkoutState: action.payload, loading: false, error: null }

    case ADD_CHECKOUT_SUCCESS:
    case DELETE_CHECKOUT_SUCCESS:
      return { ...state, loading: false, error: null }

    case GET_CHECKOUT_FAILURE:
    case ADD_CHECKOUT_FAILURE:
    case DELETE_CHECKOUT_FAILURE:
      return { ...state, loading: false, error: action.error }

    default:
      return state
  }
}

export default checkoutReducer
