import React from 'react'
import { Navigate } from 'react-router-dom'
const RedirectComponent = React.lazy(() => import('src/components/RedirectComponent'))
const Search = React.lazy(() => import('src/components/Search'))
const AddressBook = React.lazy(() => import('src/pages/AddressBook'))
const Blogs = React.lazy(() => import('src/pages/Blogs'))
const BulkEnquiry = React.lazy(() => import('src/pages/BulkEnquiry'))
const Cart = React.lazy(() => import('src/pages/Cart'))
const Catalog = React.lazy(() => import('src/pages/Catalog'))
const CatalogProducts = React.lazy(() => import('src/pages/CatalogProducts'))
const Checkout = React.lazy(() => import('src/pages/Checkout'))
const ContactUs = React.lazy(() => import('src/pages/ContactUs'))
const History = React.lazy(() => import('src/pages/History'))
const Home = React.lazy(() => import('src/pages/Home'))
const Order = React.lazy(() => import('src/pages/Order'))
const PrivacyPolicy = React.lazy(() => import('src/pages/PrivacyPolicy'))
const Product = React.lazy(() => import('src/pages/Product'))
const Profile = React.lazy(() => import('src/pages/Profile'))
const RecipeBook = React.lazy(() => import('src/pages/RecipeBook'))
const TermCondition = React.lazy(() => import('src/pages/TermCondition'))
const DeletionRequest = React.lazy(() => import('src/pages/privacy/DeletionRequest'))
const Privacy = React.lazy(() => import('src/pages/privacy/PrivacyPolicy'))

const authProtectedRoutes = [
  { path: '/cart', component: <Cart /> },
  { path: '/checkout', component: <Checkout /> },
  { path: '/profile', component: <Profile /> },
  { path: '/address-book', component: <AddressBook /> },
  { path: '/order', component: <Order /> },
  { path: '/order-history', component: <History /> },
  { path: '/bulk-enquiry', component: <BulkEnquiry /> },
  { path: '*', exact: true, component: <Navigate to="/" /> },
]

const publicRoutes = [
  { path: '/', component: <Home /> },
  { path: '/contact-us', component: <ContactUs /> },
  { path: '/privacy-policy', component: <PrivacyPolicy /> },
  { path: '/term-and-condition', component: <TermCondition /> },
  { path: '/blogs', component: <Blogs /> },
  { path: '/search', component: <Search /> },
  { path: '/catalog/:name/:id', component: <Catalog /> },
  { path: '/products/:categoryId/:subcategoryId', component: <CatalogProducts /> },
  { path: '/product', component: <Product /> },
  { path: '/recipe-book', component: <RecipeBook /> },
  { path: '/deleted', component: <DeletionRequest /> },
  { path: '/privacy', component: <Privacy /> },
  { path: '/app', component: <RedirectComponent /> },
]

export { authProtectedRoutes, publicRoutes }
