import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

const Loading = ({ isLoading }) => {
  const { loading: locationLoading } = useSelector((state) => state.location)
  const { loading: bannerLoading } = useSelector((state) => state.banner)
  const { loading: menuLoading } = useSelector((state) => state.menus)
  const { loading: loginLoading } = useSelector((state) => state.login)
  const { loading: verifyLoading } = useSelector((state) => state.verify)
  const { loading: cartLoading } = useSelector((state) => state?.cart)
  const { loading: checkoutLoading } = useSelector((state) => state?.checkout)
  const { loading: masterdataLoading } = useSelector((state) => state?.masterdata)
  const { loading: couponsLoading } = useSelector((state) => state?.coupons)
  const { loading: subcategoriesLoading } = useSelector((state) => state.subcategories)
  const { loading: productsLoading } = useSelector((state) => state.products)
  const { loading: myprofileLoading } = useSelector((state) => state.myprofile)
  const { loading: ordersLoading } = useSelector((state) => state.orders)
  const { loading: orderhistoryLoading } = useSelector((state) => state.orderhistory)
  const { loading: privacypolicyLoading } = useSelector((state) => state.privacypolicy)
  const { loading: termsandconditionsLoading } = useSelector((state) => state.termsandconditions)

  const stateLoading = useMemo(
    () =>
      isLoading ||
      locationLoading ||
      bannerLoading ||
      menuLoading ||
      loginLoading ||
      verifyLoading ||
      cartLoading ||
      checkoutLoading ||
      masterdataLoading ||
      couponsLoading ||
      subcategoriesLoading ||
      productsLoading ||
      myprofileLoading ||
      ordersLoading ||
      orderhistoryLoading ||
      privacypolicyLoading ||
      termsandconditionsLoading,
    [
      isLoading,
      locationLoading,
      bannerLoading,
      menuLoading,
      loginLoading,
      verifyLoading,
      cartLoading,
      checkoutLoading,
      masterdataLoading,
      couponsLoading,
      subcategoriesLoading,
      productsLoading,
      myprofileLoading,
      ordersLoading,
      orderhistoryLoading,
      privacypolicyLoading,
      termsandconditionsLoading,
    ],
  )

  return (
    <React.Fragment>
      {stateLoading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Loading
