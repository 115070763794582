import { toast } from 'react-toastify'
import URL from 'src/utils/URL'

const GET_SUBCATEGORIES_REQUEST = 'GET_SUBCATEGORIES_REQUEST'
const GET_SUBCATEGORIES_SUCCESS = 'GET_SUBCATEGORIES_SUCCESS'
const GET_SUBCATEGORIES_FAILURE = 'GET_SUBCATEGORIES_FAILURE'

export const getSubCategoriesRequest = () => ({
  type: GET_SUBCATEGORIES_REQUEST,
})

export const getSubCategoriesSuccess = (subcategories) => ({
  type: GET_SUBCATEGORIES_SUCCESS,
  subcategories,
})

export const getSubCategoriesFailure = (error) => ({
  type: GET_SUBCATEGORIES_FAILURE,
  error,
})

export const fetchSubCategories = (categoryId) => {
  return (dispatch) => {
    dispatch(getSubCategoriesRequest())

    fetch(URL.BASE_URL + URL.END_POINT.subcategories + categoryId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then(async (subcategories) => {
        await dispatch(getSubCategoriesSuccess(subcategories))
      })
      .catch((error) => {
        dispatch(getSubCategoriesFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialSubCategoriesState = {
  subcategories: null,
  loading: false,
  error: null,
}

const subCategoriesReducer = (state = initialSubCategoriesState, action) => {
  switch (action.type) {
    case GET_SUBCATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        subcategories: action.subcategories.subcategories,
        loading: false,
        error: null,
      }
    case GET_SUBCATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default subCategoriesReducer
