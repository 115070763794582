import { toast } from 'react-toastify'
import URL from '../../utils/URL'

// Action Types
const FETCH_COUPONS_REQUEST = 'FETCH_COUPONS_REQUEST'
const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS'
const FETCH_COUPONS_FAILURE = 'FETCH_COUPONS_FAILURE'

const ADD_COUPON_REQUEST = 'ADD_COUPON_REQUEST'
const ADD_COUPON_SUCCESS = 'ADD_COUPON_SUCCESS'
const ADD_COUPON_FAILURE = 'ADD_COUPON_FAILURE'

// Action Creators
const fetchCouponsRequest = () => ({ type: FETCH_COUPONS_REQUEST })
const fetchCouponsSuccess = (coupons) => ({ type: FETCH_COUPONS_SUCCESS, payload: coupons })
const fetchCouponsFailure = (error) => ({ type: FETCH_COUPONS_FAILURE, error })

const addCouponRequest = () => ({ type: ADD_COUPON_REQUEST })
const addCouponSuccess = (coupon) => ({ type: ADD_COUPON_SUCCESS, payload: coupon })
const addCouponFailure = (error) => ({ type: ADD_COUPON_FAILURE, error })

// Thunks
export const fetchCoupons = (code) => async (dispatch) => {
  dispatch(fetchCouponsRequest())
  try {
    const response = await fetch(URL.BASE_URL + URL.END_POINT.coupons + code, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })

    const data = await response.json()

    if (!response.ok) {
      toast.error(data.message)
      console.log(data.message)
      dispatch(fetchCouponsFailure(data.message))
    } else {
      console.log('Coupons fetched successfully')
      dispatch(fetchCouponsSuccess(data))
    }
  } catch (error) {
    toast.error(error.message)
    dispatch(fetchCouponsFailure(error.message))
  }
}

export const addCoupon = (coupon) => async (dispatch) => {
  dispatch(addCouponRequest())
  try {
    const response = await fetch(URL.BASE_URL + URL.END_POINT.coupons, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(coupon),
    })

    const data = await response.json()

    if (!response.ok) {
      toast.error(data.message)
      dispatch(addCouponFailure(data.message))
    } else {
      toast.success('Coupon added successfully')
      dispatch(addCouponSuccess(data))
    }
  } catch (error) {
    toast.error(error.message)
    dispatch(addCouponFailure(error.message))
  }
}

// Initial State
const initialState = {
  loading: false,
  coupons: [],
  error: null,
}

// Reducer
const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUPONS_REQUEST:
    case ADD_COUPON_REQUEST:
      return { ...state, loading: true, error: null }

    case FETCH_COUPONS_SUCCESS:
      return { ...state, loading: false, coupons: action.payload }

    case ADD_COUPON_SUCCESS:
      return { ...state, loading: false, coupons: [...state.coupons, action.payload] }

    case FETCH_COUPONS_FAILURE:
    case ADD_COUPON_FAILURE:
      return { ...state, loading: false, error: action.error }

    default:
      return state
  }
}

export default couponReducer
