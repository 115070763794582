import { toast } from 'react-toastify'
import URL from '../../utils/URL'

const ADD_ORDER_REQUEST = 'ADD_ORDER_REQUEST'
const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS'
const ADD_ORDER_FAILURE = 'ADD_ORDER_FAILURE'

const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST'
const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE'

const addOrderRequest = () => ({ type: ADD_ORDER_REQUEST })
const addOrderSuccess = (data) => ({ type: ADD_ORDER_SUCCESS, payload: data })
const addOrderFailure = (error) => ({ type: ADD_ORDER_FAILURE, error })

const getOrdersRequest = () => ({ type: GET_ORDERS_REQUEST })
const getOrdersSuccess = (data) => ({ type: GET_ORDERS_SUCCESS, payload: data })
const getOrdersFailure = (error) => ({ type: GET_ORDERS_FAILURE, error })

export const addOrder = (body) => {
  return async (dispatch) => {
    dispatch(addOrderRequest())
    try {
      const jsonValue = await window.localStorage.getItem('USERID')
      const response = await fetch(URL.BASE_URL + URL.END_POINT.orders + jsonValue?.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(body),
      })

      const data = await response.json()

      if (!response.ok) {
        toast.error(data.message)
        dispatch(addOrderFailure(data.message))
      } else {
        dispatch(addOrderSuccess(data))
        return data
      }
    } catch (error) {
      dispatch(addOrderFailure(error.message))
      toast.error(error.message)
    }
  }
}

export const getOrders = () => {
  return async (dispatch) => {
    dispatch(getOrdersRequest())
    try {
      const jsonValue = await window.localStorage.getItem('USERID')
      const response = await fetch(URL.BASE_URL + URL.END_POINT.orders + jsonValue?.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok) {
        dispatch(getOrdersFailure(data.message))
        toast.error(data.message)
      } else {
        dispatch(getOrdersSuccess(data))
      }
    } catch (error) {
      dispatch(getOrdersFailure(error.message))
      toast.error(error.message)
    }
  }
}

const initialOrderState = {
  loading: false,
  orders: [],
  order: {},
  error: null,
}

const orderReducer = (state = initialOrderState, action) => {
  switch (action.type) {
    case ADD_ORDER_REQUEST:
    case GET_ORDERS_REQUEST:
      return { ...state, loading: true, error: null }

    case ADD_ORDER_SUCCESS:
      return { ...state, loading: false, order: action.payload.orders, error: null }

    case GET_ORDERS_SUCCESS:
      return { ...state, loading: false, orders: action.payload.data, error: null }

    case ADD_ORDER_FAILURE:
    case GET_ORDERS_FAILURE:
      return { ...state, loading: false, error: action.error }

    default:
      return state
  }
}

export default orderReducer
