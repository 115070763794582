import { toast } from 'react-toastify'
import URL from 'src/utils/URL'

// Action Types
const GET_BANNER_REQUEST = 'GET_BANNER_REQUEST'
const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS'
const GET_BANNER_FAILURE = 'GET_BANNER_FAILURE'

// Action Creators
export const getBannerRequest = () => ({
  type: GET_BANNER_REQUEST,
})

export const getBannerSuccess = (banner) => ({
  type: GET_BANNER_SUCCESS,
  banner,
})

export const getBannerFailure = (error) => ({
  type: GET_BANNER_FAILURE,
  error,
})

// Thunk Action for Fetching Banner
export const fetchBanner = () => {
  return (dispatch) => {
    dispatch(getBannerRequest())

    fetch(URL.BASE_URL + URL.END_POINT.banner, {
      method: 'GET',
      // mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then(async (banner) => {
        await dispatch(getBannerSuccess(banner))
        // await toast('Banner fetched successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(getBannerFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

// Initial State
const initialBannerState = {
  banner: null,
  loading: false,
  error: null,
}

// Reducer
const bannerReducer = (state = initialBannerState, action) => {
  switch (action.type) {
    case GET_BANNER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_BANNER_SUCCESS:
      return {
        ...state,
        banner: action.banner,
        loading: false,
        error: null,
      }
    case GET_BANNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default bannerReducer
