import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { thunk } from 'redux-thunk'
import loginReducer from './reducer/loginwithotp'
import bannerReducer from './reducer/getBanner'
import menusReducer from './reducer/getMenus'
import subCategoriesReducer from './reducer/getSubCategories'
import productsReducer from './reducer/getProducts'
import addtocartReducer from './reducer/addtocart'
import profileReducer from './reducer/myprofile'
import privacyPolicyReducer from './reducer/privacypolicy'
import termsAndConditionsReducer from './reducer/termsandconditions'
import cartReducer from './reducer/cart'
import couponReducer from './reducer/coupons'
import masterDataReducer from './reducer/masterdata'
import checkoutReducer from './reducer/checkout'
import orderReducer from './reducer/orders'
import orderHistoryReducer from './reducer/orderhistory'
import verifyReducer from './reducer/verifyotp'
import locationReducer from './reducer/getLocation'

const rootReducer = combineReducers({
  location: locationReducer,
  login: loginReducer,
  verify: verifyReducer,
  banner: bannerReducer,
  menus: menusReducer,
  subcategories: subCategoriesReducer,
  products: productsReducer,
  cart: cartReducer,
  addtocart: addtocartReducer,
  checkout: checkoutReducer,
  orders: orderReducer,
  orderhistory: orderHistoryReducer,
  myprofile: profileReducer,
  coupons: couponReducer,
  masterdata: masterDataReducer,
  privacypolicy: privacyPolicyReducer,
  termsandconditions: termsAndConditionsReducer,
})

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
export default store
