import { toast } from 'react-toastify'
import URL from 'src/utils/URL'

export const loginRequest = () => ({
  type: 'LOGIN_REQUEST',
})

export const loginSuccess = (user) => ({
  type: 'LOGIN_SUCCESS',
  user,
})

export const loginFailure = (error) => ({
  type: 'LOGIN_FAILURE',
  error,
})

export const loginUser = (credentials) => {
  return (dispatch) => {
    dispatch(loginRequest())

    fetch(URL.BASE_URL + URL.END_POINT.login, {
      method: 'POST',
      // mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(credentials),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then(async (user) => {
        await dispatch(loginSuccess(user))
        await toast(user.message, { type: 'success' })
      })
      .catch((error) => {
        dispatch(loginFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialLoginState = {
  isLoggedIn: false,
  user: null,
  loading: false,
  error: null,
}

const loginReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isLoggedIn: true,
        user: action.user,
        loading: false,
        error: null,
      }
    case 'LOGIN_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case 'LOGOUT':
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      }
    default:
      return state
  }
}

export default loginReducer
