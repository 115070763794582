import "react-toastify/dist/ReactToastify.css";
import "react-app-polyfill/stable";
import "core-js";
import { ToastContainer } from "react-toastify";
import React from "react";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./index.css";
import ReactDOM from "react-dom";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <ToastContainer position="top-right" />
    <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
