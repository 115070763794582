// import { fetchAndActivate, getValue } from 'firebase/remote-config'
import { toast } from 'react-toastify'
import URL from 'src/utils/URL'
// import { remoteConfig } from 'src/utils/firebase'
import { getFCMToken } from 'src/utils/getFCMToken'

const VERIFY_REQUEST = 'VERIFY_REQUEST'
const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
const VERIFY_FAILURE = 'VERIFY_FAILURE'
const LOGOUT = 'LOGOUT'

export const verifyRequest = () => ({
  type: VERIFY_REQUEST,
})

export const verifySuccess = (user) => ({
  type: VERIFY_SUCCESS,
  user,
})

export const verifyFailure = (error) => ({
  type: VERIFY_FAILURE,
  error,
})

export const logout = () => ({
  type: LOGOUT,
})

export const verifyUser = (credentials) => {
  return async (dispatch) => {
    dispatch(verifyRequest())
    const fcm_token = await getFCMToken()
    // await fetchAndActivate(remoteConfig)
    // const BASE_URL = getValue(remoteConfig, 'BASE_URL').asString()

    fetch(URL.BASE_URL + URL.END_POINT.verify, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Fcm_token: fcm_token,
      },
      credentials: 'include',
      body: JSON.stringify({ ...credentials, fcm_token }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((user) => {
        if (user.success) {
          dispatch(verifySuccess(user))
          toast(user.message, { type: 'success' })
          localStorage.setItem('token', user.token)
          localStorage.setItem('USERID', user.USERID)
          // document.cookie = `token=${user.token}; path=/; SameSite=Strict; Secure`
          window.location.reload()
        }
      })
      .catch((error) => {
        dispatch(verifyFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialLoginState = {
  isLoggedIn: false,
  user: null,
  loading: false,
  error: null,
}

const verifyReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case VERIFY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case VERIFY_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: action.user,
        loading: false,
        error: null,
      }
    case VERIFY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      }
    default:
      return state
  }
}

export default verifyReducer
